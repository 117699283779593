import React from 'react'
import { graphql } from 'gatsby'

import CommercialTemplate from '../../pages/commercial/[industry]/[subindustry]'

const PageTemplate = ({ data, location }) => (
  <CommercialTemplate
    data={data}
    location={location}
    params={{ industry: null, subindustry: null }}
  />
)

export default PageTemplate

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxMTQ2Mw==" }) {
      seo {
        ...PostSEO
      }
      commercialServices {
        hero {
          paragraph
          headline
          ctaButton {
            title
            url
          }
          background {
            localFile {
              publicURL
            }
          }
        }
        defaultState {
          headline
          paragraph
        }
        footerCta {
          ctaButton {
            title
            url
          }
          headline
          paragraph
        }
        cardGroup {
          headline
          paragraph
          cards {
            headline
            paragraph
            icon {
              localFile {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
        industriesHeading {
          headline
          paragraph
        }
      }
    }
  }
`
